import { useRef } from "react";

const useVisible =(setClose?:Function )=>{
    const Myref = useRef<HTMLInputElement>(null)
    const setOpen = ()=>{
        if (!Myref.current) return;
        Myref.current.style.display = "block";
    }
    const Close = ()=>{
        if (!Myref.current) return;
        Myref.current.style.display = "none";
        if(setClose) setClose();
    }
    const onclikClose = (ev:any)=>{

        if(ev.target.className ===Myref.current?.className ){
            ev.target.style.display = "none";
            if(setClose) setClose();
        }
    }
    return {
        Close,
        onclikClose,
        setOpen,
        Myref
    }
} 

export default useVisible;