import React, {useState} from "react";
import Inquiries from "../../../Api/Inquiries";

import config from "../../../config";
// TODO poner operativo el formulario de contacto 
// y mover la logica creando otro componente 
const Telefono = ()=>{
    return (
        <div className='contacto__text' >
        <img className='contacto__img' src={`${config.baseUrl}/img/telefono.svg`}
         alt="" />
        <p>+56-9-54732246</p>
       
        </div>
    )
}
const Email = ()=>{
    return (
        <div className='contacto__text' >
        <img className='contacto__img' src={`${config.baseUrl}/img/sobre.svg`}
         alt="" />
        <p>rodwinprimera1@gmail.com</p>
        </div>
    )
}
const Direccion = ()=>{
    return (
        <div className='contacto__text' >
          <img className='contacto__img' src={`${config.baseUrl}/img/marcador.svg`}
           alt="" />
         <p>Quilpué - Chile</p>
         
          </div>
    )
}
const RedesSociales = ()=>{
    return (
        <div style={{margin: "10px"}} >
            <a href="https://www.linkedin.com/in/rodwin-primera-2438781a9/"  target="_blank" ><img className='contacto__img' src={`${config.baseUrl}/img/linkedin.svg`}
           alt="" /></a>
            <a href="https://www.instagram.com/rodwinprimera/?utm_source=qr"  target="_blank" ><img className='contacto__img' src={`${config.baseUrl}/img/instagram.svg`}
           alt="" /></a>
            <a href="https://www.facebook.com/rodwin.primera"  target="_blank" ><img className='contacto__img' src={`${config.baseUrl}/img/facebook.svg`}
           alt="" /></a>



          
          
           <img className='contacto__img' src={`${config.baseUrl}/img/github.svg`}
           alt="" />
          </div>
    )
}
const DatosContacto = ()=>{
    return (
        <div className='contacto__datos' > 
             <h2 id ="Contacto" className='contacto__titulo' >Datos de contacto</h2>
             <Telefono />
             <Email />
             <Direccion />
             < RedesSociales/>
        </div>
    )
}

const FormularioDeContacto = ()=>{
    const [data, setdata] = useState({
        name:"",
        email:"",
        message:""
    })
    let onChange = (e)=>{
        console.log(e)
        setdata((data)=>({...data,[e.target.name]:e.target.value}))
    }
    let onsubmitForm = (event)=>{
        event.preventDefault()
        Inquiries.Query(data,'CodeIgniter/PruebaCorreo','post')
        .then(res=>{
            alert('Se Envio con Existo')
            setdata({
                name:"",
                email:"",
                message:""
            })
        .catch(()=>{alert('Error Con el servidor')})
    })
           
    console.log(event.target)
    }
    return (
        <form action = "https://rodwindemo.cl/CodeIgniter/PruebaCorreo"  method="post" className='contacto__form' onSubmit ={onsubmitForm} >
            
        
        
        <input onChange={onChange} className='imput__simple' name="name" placeholder="name" type="text" required value = {data.name} />
        <input onChange={onChange} className='imput__simple' name="email" pattern="+@+.com" placeholder="email"  type="email" value={data.email} required />
        <textarea onChange={onChange}  rows={3} cols={30} className='imput__simple'  style={{resize:"none"}} name="message"  placeholder="message" required value={data.message} />
        <button className="btn__48" style={{ padding:"1rem", width:"95%", margin:"10px"}} ><span className="text"  >Enviar</span></button>
      
        </form>
    )
}

const Footer = ()=>{
    return (
    <footer className='contacto' >
         <div className='contacto__container' >
           <DatosContacto/>
           <FormularioDeContacto/>
         </div>
    </footer>
    )
}

export default Footer