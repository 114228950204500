import React from 'react'
import Cart1 from '../../Generales/cart1/Cart1';
import Titulo from '../../Generales/Titulo/Titulo';

const CardInfo = () => {
  return (
    <div>
          <Titulo id ="Perfil" titulo = "Perfil" />
    
   <div className='cart' >   
    <div className='cart__layout'>
     < Cart1 
     titulo = "Biografía" 
     text = " Nací el 10 diciembre de 1987, en Venezuela.Realice mis estudios en la Universidad Pedagógica Experimental Luis Beltrán Prieto Figueroa, en la cual optuve el título de Profesor en la especialidad de Matemática, siendo uno de los mejores promedios, paralelamente,estudie  en el Colegio Universitario Fermín Toro egresando como Técnico Superior Universitaro en Informática mención Programación,lo cual despertó en mi un gran intéres por el mundo de la programación." 
     
     />
     < Cart1 
     img =  "https://heric-olier-frontend-developer.netlify.app/assets/about/about-image-complete.svg"
     />
     < Cart1 
     titulo = "Actualmente" 
     text = "Actualmente desempeño el puesto de desarrollador Senior en G Y G SERVICIO DE GESTION TIC Y CONSULTORIA LTDA. con más de 3 años de experiencia brindando soluciones informáticas a problemas empresariales. Entre mis actuales funciones se encuentran liderazgo, organización, planificación y gestión de proyectos, vinculándome directamente en todos los procesos del ciclo de vida, asegurándome en todo momento en cumplir los estándares esperados en cada una de las aplicaciones." 
     
     />
      < Cart1 
       img = "https://freesvg.org/img/1427499348.png"
     />
     </div>
    </div>
    </div>
  )
}

export default CardInfo