import { apiFetch } from './ApiFetch';
import Config from  '../config'
const direction = Config.baseUrl+ "/";
const Post = {
    method: "POST",
    headers: {
     // 'credentials': 'same-origin',
     'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyIjoxMH0=.6lwGPZwL2tpkqg/ux6wZyXZHbsHoWXjipzfkhebdh/8=',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({}) 
  };
  const Get = {
    method: "GET",
    headers: {
      'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyIjoxMH0=.6lwGPZwL2tpkqg/ux6wZyXZHbsHoWXjipzfkhebdh/8=',
      'Content-Type': 'application/json',
    },
  };

  const Put = {
    method: "PUT",
    headers: {
     // 'credentials': 'same-origin',
     'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyIjoxMH0=.6lwGPZwL2tpkqg/ux6wZyXZHbsHoWXjipzfkhebdh/8=',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({})
  };
  const Delete = {
    method: "DELETE",
    headers: {
     // 'credentials': 'same-origin',
    'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyIjoxMH0=.6lwGPZwL2tpkqg/ux6wZyXZHbsHoWXjipzfkhebdh/8=',
      'Content-Type': 'application/json',
    },
  };


  class Inquiries {
    static Query(Cuerpo:object|null=null, endpoint:string = '', Met:"get"|"post"|"put"|"delete"|"Paralelo"|"" = "") {
       
       switch(Met){
           case 'get':
              
               return apiFetch(direction+endpoint, Get,true)
           case 'post':
               Post.body = JSON.stringify(Cuerpo);
               console.log(Post)
               return apiFetch(direction+endpoint,Post)
            case 'put':
              console.log(Put)
              Put.body = JSON.stringify(Cuerpo);
              return apiFetch(direction+endpoint,Put)
            case 'delete':
              return apiFetch(direction+endpoint,Delete,true)
            case 'Paralelo':
                Post.body =  JSON.stringify(Cuerpo);
                console.log(Post)
                return apiFetch(endpoint,Post,true)
           default:
               break;
       }
     return new Promise((resolve, reject)=>{
          return reject({Erro: "Parametos Errados"});
     })
      
  
    }
  }
  export default Inquiries;