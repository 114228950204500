import {action } from '../../interfaces/interfaces'
import Inquiries from '../../Api/Inquiries'

export function ModificarTitulo (State:string):action{
    return {
        type:"Title",
        payload:State
    }
}
export function Prueba (State?:string):action{
    return {
        type:'Pepe',
        payload:Inquiries.Query(null,"User","get") 
    }
}