import React from 'react'
import { useParams } from "react-router-dom";
const OptenerTop = (ID:string)=>{
    let top = -1;
    let y = window.scrollY
    if(document.getElementById(ID)!= null){
      const TopPerfil = document.getElementById(ID)
      if(TopPerfil){
        top = TopPerfil.getBoundingClientRect().top +y
      }
    }
    return top;
  }

  const Desactivar = (id:string)=>{
    let elm= document.getElementById('Link'+id)
    if(elm){
      elm.classList.remove('active');
    }
  }

  const Activar =(id:string)=>{
    let elm= document.getElementById('Link'+id)
    if(elm){
      elm.classList.toggle('active');
    }
  }

  const ActivarClase=(id:string)=>{
    Desactivar("Perfil")
     Desactivar("Inicio")
     Desactivar("Tecnologias")
     Desactivar("Proyectos")
     Desactivar("Estudios")
     Desactivar("Contacto")
     Activar(id)
  }

  const useHome = ()=>{
    const props = useParams();
    React.useEffect(() => {
    

        const ScrollFn = function (eve:Event){
          let y = window.scrollY
          let TopPerfil = OptenerTop("Perfil");
          let TopInicio = OptenerTop("Home");
          let TopTenologias = OptenerTop("Tecnologias");
          let TopProyectos = OptenerTop("Proyectos");
          let TopEstudios = OptenerTop("Estudios");
          let TopContacto = OptenerTop("Contacto")
           if(TopInicio <y  &&  (y ) < TopPerfil-100  ){
            ActivarClase("Inicio")
           }
          if(TopPerfil-100 <y  &&  (y ) < TopTenologias-100 ){
            ActivarClase("Perfil")
           }
          if(TopTenologias-100 <y  &&  (y ) < TopProyectos-100  ){

            ActivarClase("Tecnologias")
           
           }
          if(TopProyectos-100 <y  &&  (y ) < TopEstudios-100 ){
            ActivarClase("Proyectos")
           }
          if(TopEstudios-100 <y  &&  (y+500 ) < TopContacto ){

           ActivarClase("Estudios")
           
          }
  
          if(TopEstudios < y  &&  (y + 500) > TopContacto  ){
            ActivarClase("Contacto")
            
           }
          
          
      
        }
        window.addEventListener('scroll',ScrollFn );
       
        return () => {
          window.removeEventListener("scroll",ScrollFn)
        }
         }, [])
         if (props.Item){
            const elemento = document.getElementById(props.Item);
            if(elemento != null){
            
              let y = window.scrollY
              window.scroll({
                top: elemento.getBoundingClientRect().top +y,
                left: elemento.getBoundingClientRect().left,
                behavior: 'smooth'
              });
            }
            
          
          }
    return {
   
    }
  }


export default useHome