import React from 'react';
import SideBar from './component/Generales/SideBar/SideBar';
import { IoPersonCircle } from "react-icons/io5";
import { IoHome, IoCalculatorSharp, IoFolderOpen, IoMedalOutline, IoMailOpenOutline } from "react-icons/io5"

function App({children } : any ) {
  let items = [
    {Colapse:true,Icon:IoHome,label:"Inicio", url: '/Home/Home'},
    {Colapse:true,Icon:IoPersonCircle,label:"Perfil", url: '/Home/Perfil'},
    {Colapse:true,Icon:IoCalculatorSharp,label:"Tecnologías", url: '/Home/Tecnologias'},
    {Colapse:true,Icon:IoFolderOpen,label:"Proyectos", url: '/Home/Proyectos'},
    {Colapse:true,Icon:IoMedalOutline,label:"Estudios", url: '/Home/Estudios'},
    {Colapse:true,Icon:IoMailOpenOutline,label:"Contacto", url: '/Home/Contacto'},
  ]
  return (
    <div id={"App"} className="App Claro">
       <SideBar Items= {items} >
       {children}
       </SideBar>
    </div>
  );
}

export default App;
