
//TODO mover la logica a un custonHook
import React from 'react'
import useRenderHexagonos from './useRenderHexagonos';


interface Hexagonos {
  name: string,
  color: string,
  url: string,
  with?:string
}
interface props {
  items: Hexagonos[]
  Toltip?:boolean,
  width?:string,
  ID?:string
}


const HexagonosIcon = ({items, Toltip, width = "50%", ID="Tecno"}: props) => {
  const { tope, ModificarHeightContenedor } = useRenderHexagonos(ID)

       let top =0;
       let contador = -1
       let filas = 1
  return (
    <div id={ID} className='Tecnologia' style={{width:width}}  >
    {
     
     items.map((el,key)=>{
      contador++
      if  (contador > tope)
      {
        contador =0
        filas++;
        top =top +55
      }
      let modulo = filas%2
      let pos =0
      if(modulo==0){
        pos= 32+( contador*65)
      }else{
        pos= contador*65
      }
      ModificarHeightContenedor(ID,top)
      if(Toltip){
        return (<div key = {key} style ={{left:(pos), top:top, background: el.color}} className='hex'>
        <div className="inner tooltips">
       <img src={el.url} alt="" width={ el.with ? el.with :'40px'} />
       <span className="tooltiptexts">{el.name}</span>
     </div>	
     <div className="corner-1"></div>
     <div className="corner-2"></div>	
    
 

        </div>)
      }else{
        return (<div key = {key} style ={{left:(pos), top:top, background: el.color}} className='hex hex-2'>
        <div className="inner">
       <img src={el.url} alt="" width={ el.with ? el.with :'40px'} />
     </div>	
     <div className="corner-1"></div>
     <div className="corner-2"></div>	
    
 

        </div>)
      }
       
      
})
    }
 
  
  </div>
  )
}

export default HexagonosIcon