import React from 'react'
import ProyectInventario from '../ProyectInventario/ProyectInventaro';
import ProyectLearning from '../ProyectLearning/ProyectLearning';
import ProyectBalsas from '../ProyectBalsas/ProyectBalsas';
import ProyectInventario1 from '../ProyectoInventario1/ProyectInventario1';
import Titulo from '../../Generales/Titulo/Titulo';
const CartsProyectos = () => {

  return (
    <div>
       <Titulo id ="Proyectos"  titulo = "Proyectos" />
    
   <div className='cart' >   
    <div className='cart__layout'>
      <ProyectInventario />
      <ProyectLearning />
      <ProyectBalsas />
      <ProyectInventario1 />
    </div>
    </div>
    </div>
    
  )
}

export default CartsProyectos