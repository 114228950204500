
//Todo crear Custon hoosk solo para mover la logica
import React, { useState } from 'react';
import config from '../../../config';
import Modal from '../Modal/Modal';
interface props{
    img?:string,
    text?: string,
    cover?: boolean,
    pdf:string[],
    ico?:string,
    link:string,

}

const Cart4 = ({img = "https://d2wu8443505y4l.cloudfront.net/img/institutions/2021.png", text="", cover, pdf, ico="https://www.aluracursos.com/assets/api/cursos/arquitectura-css-descomplicando-problemas.svg", link}:props)=>{
   const [Open, setOpen] = useState(false)
  let   objStile =  {background:'linear-gradient(135deg, var(--cart4-background-color-transparente) 15%, transparent 20% 60%), url("'+img+'")',  backgroundRepeat: "no-repeat",
  backgroundPosition: "center", backgroundSize:"auto auto"}
    if (cover) objStile.backgroundSize ="cover";
    return (
      <div>
        <div className='caja4__container' >
        <div onClick={()=>setOpen(!Open)} className='caja4__img' style={objStile} >
  
        </div>
        <div className='caja4__text' >
          <img  src={ico} alt="" />
          <span  >
          {text}
          </span>
        </div>
        
      </div>
     
      <Modal 
      Open ={Open} 
      setClose={()=>setOpen(false)}
      Titulo={ <div className='caja4__text' >
      <img  src={ico} alt="" />
      <span  >
      {text}
      </span>
    </div>}
      footer={<div className='caja4__text'  >
          
        <a href={link} target="_blank" ><img  src={img} alt="" /></a>
      </div>}
       >

<embed src={`${config.baseUrl}/Cursos/${pdf[0]}`} type="application/pdf" width="100%" height="400px" />
       </Modal>

      
    
      </div>
        
    )
}

export default Cart4;