
export function encode_utf8(unicodeString: string):String {
    if (typeof unicodeString != 'string') throw new TypeError('parameter ‘unicodeString’ is not a string');
    const utf8String = unicodeString.replace(
        /[\u0080-\u07ff]/g,  // U+0080 - U+07FF => 2 bytes 110yyyyy, 10zzzzzz
        function(c) {
            var cc = c.charCodeAt(0);
            return String.fromCharCode(0xc0 | cc>>6, 0x80 | cc&0x3f); }
    ).replace(
        /[\u0800-\uffff]/g,  // U+0800 - U+FFFF => 3 bytes 1110xxxx, 10yyyyyy, 10zzzzzz
        function(c) {
            var cc = c.charCodeAt(0);
            return String.fromCharCode(0xe0 | cc>>12, 0x80 | cc>>6&0x3F, 0x80 | cc&0x3f); }
    );
    return utf8String;
}

export function decode_utf8(utf8String: string):string {
    if (utf8String!=null){
     if (typeof utf8String != 'string') throw new TypeError('parameter ‘utf8String’ is not a string');
     // note: decode 3-byte chars first as decoded 2-byte strings could appear to be 3-byte char!
     const unicodeString = utf8String.replace(
         /[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g,  // 3-byte chars
         function(c) {  // (note parentheses for precedence)
             var cc = ((c.charCodeAt(0)&0x0f)<<12) | ((c.charCodeAt(1)&0x3f)<<6) | ( c.charCodeAt(2)&0x3f);
             return String.fromCharCode(cc); }
     ).replace(
         /[\u00c0-\u00df][\u0080-\u00bf]/g,                 // 2-byte chars
         function(c) {  // (note parentheses for precedence)
             var cc = (c.charCodeAt(0)&0x1f)<<6 | c.charCodeAt(1)&0x3f;
             return String.fromCharCode(cc); }
     );
     return unicodeString;
    }else{
       return "";
    }
 }
 