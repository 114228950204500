import React,{useState} from "react";
import Switch from "../../Personalizados/Switch/Switch";
const Desactivar = (id:string)=>{
    let elm= document.getElementById(id)
    if(elm){
      elm.classList.remove('Oscuro');
      elm.classList.remove('Claro');
    }
  }

  const Activar =(id:string,classe:string)=>{
    let elm= document.getElementById(id)
    if(elm){
      elm.classList.toggle(classe);
    }
  }
const SwitchLightDark = ()=>{
    const [isChecked, setIsChecked] = useState(false);
    const onchaneSwitch= (e:any)=>{
        Desactivar("App");
        if(isChecked===false){
            Activar("App","Oscuro")
        }else{
            Activar("App","Claro")
        }
       
      setIsChecked(!isChecked);
    }
    return (
    
            <Switch onChange={onchaneSwitch}  name="color_mode" id="color_mode" checked={isChecked} value="1"/>
            
    )
}

export default  SwitchLightDark;