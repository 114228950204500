import React from "react";
import HexagonosIcon from "../../Generales/HexagonosIcon/HexagonosIcon";
import Titulo from "../../Generales/Titulo/Titulo";
import DataTecnologias from "../../../Data/DataTecnologias";
const Tecnologias = ()=>{
   
    return (
        <div>
            <Titulo id ="Tecnologias" titulo = "Tecnologías" />
            <HexagonosIcon  items ={DataTecnologias} Toltip={true}/>
        </div>
    )
}

export default Tecnologias;