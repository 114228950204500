import { combineReducers } from 'redux';

// Apps Reducers 

// Shared Reducers
import Init from './deviceReducer';
import Home from './HomeReducer';
//import Caja from './reducerCaja';

//import Button from './ReducerCommand';
// es un reducer generico que combinara todos los reducer en uno solo ejecutando cada reducer

const rootReducer = combineReducers({
  Init,
  Home
});

export default rootReducer;