import React from 'react'
import { IoMenu } from "react-icons/io5";
import { IconType } from "react-icons";
import {NavLink } from 'react-router-dom';
 interface MenuItems {
  Colapse:boolean;
  Icon: IconType;
  label:string;
  url:string;
 }
 interface props {
 Items: MenuItems[];
 children: JSX.Element | JSX.Element[];
 }
const SideBarMenuItems = ({ Colapse,Icon,label, url }:MenuItems):JSX.Element  =>{
  return (

    
    <div className='SideBarMenuItems' >
    <div className={Colapse ===false? 'linea' : 'linea collapsed' } ></div>
       <NavLink id = {"Link"+label} className='Sidebutton'  to={url}  > 
       <div className ={'ItemContent'} >
       <Icon className='icon' />
       {Colapse ===false ?
      <span className='label'  >{label}</span>
      :null}
       </div>
       </NavLink>
       {Colapse ?
     <span className='tooltip' >{label}</span>
      :null}
  </div>
  )
}

const SideBar = ({Items, children }: props) => {
const [Colapse, setColapse] = React.useState(true)
 const  handleExpanded = ()=>{
  setColapse(!Colapse)
 }

  return (
     <div>
      <div className={Colapse == true ? 'SideBarMenu collapsed' : 'SideBarMenu expanded' } >
         <div className={'menuButton'} >
            <button 
            className={Colapse == true ? 'hamburgerIcon ':'hamburgerIcon'}  
            onClick={handleExpanded}> 
            <IoMenu className='hamburgerIcon' />
            </button>
          </div>

          {
            Items.map((el,key)=>(
              <SideBarMenuItems  key = {key}  Icon = { el.Icon}  Colapse = {Colapse} label ={el.label} url= {el.url}    />
            ))
          }
       
      </div>
      <div className= {Colapse == true ? 'main collapsed':'main expanded'} >
      {children}
      {Colapse == true?
      <button onClick={handleExpanded} className="btn-flotante">
      <IoMenu className='hamburgerIcon' />
      </button>
   
      :null}
      
   
      </div>
     
      </div>    
  )
}

export default SideBar