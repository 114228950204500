import { connect, ConnectedProps } from 'react-redux'; 
import {initialState, Init,Home } from '../../interfaces/interfaces'
import * as actions from './actions';
// funcion que recibo el estado
const mapState = (state: initialState) => ({
  Home:state.Home
})
//funcion que resibe los accion
const mapDispatch = actions
// creamos el connect y lo guardamos enel conector 
const connector = connect(mapState, mapDispatch)
// creamos el typo de conector
type PropsFromRedux = ConnectedProps<typeof connector>
// creamos la interface props extendiendo del type 
interface Props extends PropsFromRedux {
  Home: Home
}

export type  {
    Props,
}
export {
    connector,
}