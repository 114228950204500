

type AuthAction = 
    | { type: 'Title',payload: string } 
    | { type: 'Hola' } 
    | { type: 'Pepe', payload: string }
    | { type: 'Pepe_SUCCESS', payload: RootObject[] }
  
interface Home {
    Title: string
}

  interface RootObject {
      Códig?: string;
      Rut?: string;
      Nombre?: string;
      Apellido?: string;
      Teléfono?: string;
      Correo?: string;
      Login?: string;
      Password?: string;
      Nacimiento?: string;
      Ingreso?: string;
      Nivel?: string;
      Egreso?: string;
      Tienda?: string;
      Status?: string;
  } 
let Home:Home ={"Title":""}

export default function InitReducer(state : Home = Home,action:AuthAction)  {
  
    switch (action.type) {
        case "Title":
            Home.Title = action.payload
            return  Object.assign({}, state, {...Home });  
        case "Pepe_SUCCESS":
            console.log(action.payload[0].Nombre)
            return state;  
        default:
            return state;
    }
  }
  