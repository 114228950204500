import React from "react";
import useVisible from '../../../CustonHooks/useVisible';
interface props{
    Open?:boolean,
    setClose?:Function,
    Titulo: JSX.Element | JSX.Element[] | string,
    children: JSX.Element | JSX.Element[] | string,
    footer?:JSX.Element | JSX.Element[] | string,
    xs?:boolean,
    sm?:boolean,
    md?:boolean,
    lg?:boolean,
}
const Modal = ({Open, setClose, Titulo, children,  footer, xs ,sm, md, lg}:props)=>{
    const {Close, onclikClose,Myref} = useVisible(setClose)
    let tamano = lg ?"100%": md?"768px": sm ?"576px": xs?"360px":"576px";
    return (
        <div>
           <div onClick={onclikClose} ref={Myref} className="modal" style={{display:Open?"block":"none"}} >
                <div className="modal__content" style={{maxWidth:tamano}}>
                    <div className="modal__header">
                        <span onClick={Close} className="modal__close">&times;</span>
                        {Titulo}
                    </div>
                    <div className="modal__body">{children}</div>
                    {footer
                        ?
                        <div className="modal__footer">{footer}</div>
                        :null
                    }
                </div>
            </div>
        </div>
    )
}

export default Modal;
