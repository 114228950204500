import React from 'react';
interface props {
    img?: string;
    children?: JSX.Element | JSX.Element[];
  }
const Cart3 = ({img ="https://storage.googleapis.com/chydlx/codepen/blog-cards/image-1.jpg",children}:props)=>{
    return (
    <div className='cart3__container'>
        <div className='cart3__figure' >
            <div className="cart3__photo" style={{backgroundImage: "url("+img+")"}}>
            </div>
            <div className="cart3__details">
              {
                children ? children:null
              }
            </div>
        </div>
    </div>
    )
}

export default Cart3;