import React from 'react'
const ModificarHeightContenedor =(div:string,top:number)=>{
  let element = document.getElementById(div);
      if (element){
        if (element.style){
          if (element.style){
            element.style.height = (top) +'px'
          }
         
        }
        
      }

}
const ElemWidth = (div:string)=>{
    let elem = document.getElementById(div);
    
    if(elem === null){return 9};
    return elem.getBoundingClientRect().width /64;
  }

  const ElemHeight = (div:string):number=>{
    let elem = document.getElementById(div);
    
    if(elem === null){return 9};
    return elem.clientHeight;
  }
  const OrdenarExagonos= (div:string)=>{
    ElemHeight(div)

    
    let div1 =  document.querySelectorAll('#'+div+' .hex');
    let tope = ElemWidth(div) -1
    let top =0;
    let contador = -1
    let filas = 1
    for (const div of div1 as any){ // then will pass compiler
      contador++
      if  (contador > tope)
      {
        contador =0
        filas++;
        top =top +55
      }
      let modulo = filas%2
      let pos =0
      if(modulo==0){
        pos= 32+( contador*65)
      }else{
        pos= contador*65
      }
  
      div.style.left = pos +'px';
      div.style.top = top +'px';
      
    } 
    ModificarHeightContenedor(div,top)
  }


  const useRenderHexagonos= (ID:string)=>{
    const [first, setfirst] = React.useState(false)
    React.useEffect(() => {
      setfirst(!first)
      const Resize=  function  (){
        OrdenarExagonos(ID);
    }
      window.addEventListener("resize",Resize );
    return () => {
      window.removeEventListener("resize",Resize)
    }
     }, [])
   let tope= ElemWidth(ID)
    return {
      tope,
      ModificarHeightContenedor
    }
  } 

  export default useRenderHexagonos;
