import isomorphicFetch from 'isomorphic-fetch';
import {createPromise } from 'redux-promise-middleware';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import { createStore, applyMiddleware } from 'redux';
import {initialState as init } from '../interfaces/interfaces'
interface Redux {
    dispatch: any;
    getState: any;
  }
interface StateInit { initialState:init }
const injectMiddleware = (deps : any) => ({ dispatch, getState  }: Redux) => (next:any) => (action:any) =>
  next(typeof action === 'function'
    ? action({ ...deps, dispatch, getState })
    : action
  );

  export default function configureStore ({initialState}: StateInit, rootReducer:any) {
  
    const middleware = [
      injectMiddleware({
        fetch: isomorphicFetch,
      }),
      createPromise({
        promiseTypeSuffixes: ['START', 'SUCCESS', 'ERROR']
      }),
      reduxImmutableStateInvariant()
    ];
   
    const store = createStore(rootReducer, initialState, applyMiddleware(...middleware));

    return store

  }
  