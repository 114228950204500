import React from 'react';
interface props {
    titulo?: string;
    text?:string;
    img?: string;
    children?: JSX.Element | JSX.Element[];
  }
const Cart1 = ({titulo,text,img}:props)=>{
    return (
        <div className='cart__container' >
            {
                titulo ?
                <h2 className='cart__subtitulo' >{titulo}</h2>
                 :null
            }
            {
                text ?
                <p className='cart__text' >
                    {text}
                </p>
                :null
            }
            {
                img ?
                <img className='cart__img' src={img} alt="Img" />
               
                :null
            }
        
        </div>
     
    )
}

export default Cart1;