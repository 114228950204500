export default [    {
      name:"React.js",
      color:'#000000',
      url:'https://4.bp.blogspot.com/-_YSVTe2ekBU/XKMntJDH0ZI/AAAAAAAAXNk/3d48i_XShWwvoMNj0YJWp2J4_Woh9dzGgCLcBGAs/s1600/reactjs%2Btutorial.png'
    },
    {
    name:"JavaScript",
    color:'#f5e348',
    url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/480px-Unofficial_JavaScript_logo_2.svg.png"
   },
   {
    name:"node.js",
    color:'#EBEDEF',
    url: "https://assets.zabbix.com/img/brands/nodejs.svg"
   },
   {
    name:"CSS3",
    color:'#515354',
    url : 'https://programacionavan.files.wordpress.com/2014/06/logo-icono-css3-01.jpg',
    with:'30px'
   },
   {
    name:"Html5",
    color:'#EBEDEF',
    url:"https://www.manejandodatos.es/wp-content/uploads/2013/07/html5_logo.png"
   },
   {
    name:"Sass",
    color:'#fff',
    url:'https://raw.githubusercontent.com/indievox-inc/iNDIEVOX-Web-Profile/master/image/sass-logo.png'
   },
   {
    name:"PHP",
    color: '#6c7cbb',
    url:'https://muchocodigo.com/wp-content/uploads/2013/11/php.jpg'
   },
   {
    name:"JAVA",
    color:'#EBEDEF',
    url:'https://2.bp.blogspot.com/-mc77aFdXtxU/V2QovCN7qEI/AAAAAAAANiI/QOa7GlEs-yAvODKsN1oxwayTtWeR4bdpACK4B/s400/Java-icon.png'
   },
   {
    name:"MySql",
    color:'#EBEDEF',
    url:'https://www.programandoamedianoche.com/wp-content/uploads/2014/05/MySql-Backup.png'
   },
   {
    name:"mongodb",
    color:'#EBEDEF',
    url:'https://davidrengifo.files.wordpress.com/2017/09/mongodb-logo.png'
   },
   {
    name:"sql server",
   color:'#EBEDEF',
   url:'https://www.gpsos.es/wp-content/uploads/sql_server_logo.png'
   },
   {
    name:"Python",
    color:'#515354',
    url:'https://www.cice.es/wp-content/uploads/2019/09/iconfinder_267_Python_logo_4375050.png'
   },
   {
    name:"react-native",
    color:'#EBEDEF',
    url:'https://jhonmeraraujo.files.wordpress.com/2018/01/react-native.png'
   },
   {
    name:"codeigniter",
    color:'#fff',
    url:'https://www.coriaweb.hosting/wp-content/uploads/2016/11/dc5df_codeigniter.jpg'
   },
   {
    name:"bootstrap",
    color:'#EBEDEF',
    url:'https://seocom.agency/wp-content/uploads/2019/02/bootstrap-stack.png'
   },
   {
    name:"jquery",
    color:'#fff',
    url:'https://mpng.subpng.com/20180704/zoa/kisspng-jquery-ui-javascript-web-browser-pasargad-5b3d093837a4d4.5712269815307267122279.jpg'
   },
   {
    name:"TS",
    color:'#2c64a4',
    url:'https://www.devmaking.com/assets/language-badges/ts.png'
   },
   {
    name:"mui",
    color:'#EBEDEF',
    url:'https://mui.com/static/logo.png'
   },
   {
    name:"wordpress",
    color:'#EBEDEF',
    url:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwlknei4fM_FqNMlwvhdp60NAG6NSEFFSMahLvrSQek-7sInqLiMRLs8NDKa1TXPj_2bU&usqp=CAU'
   }
  
  ];