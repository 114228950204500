

import { Init,action } from '../interfaces/interfaces'
const initialState1:Init = { 
    Sesion:{
      User:"",Level:0,Store:""},
    isMobile:false}
export default function InitReducer(state : Init = initialState1,action:action)  {


  
    return state;
  }
  