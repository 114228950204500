// TODO Verificar el error 

import queryString from 'query-string';
//import fetch from 'node-fetch';
import {decode_utf8} from '../Utils'
type HeadersInit = string[][] | Record<string, string> | Headers;
interface options {
    method:string,
    headers:HeadersInit|undefined,
    body ?:null|string|undefined
}

export function apiFetch (endpoint : string, options:options, query: any=false  ){
    
    const getPromise =  async () => {
        let qs:any;
        if (query) {
            qs = queryString.stringify(query);
          }
        try {
            const fetchOptions = apiOptions(options);
            const fetchEndpoint = apiEndpoint(endpoint, qs);
            let endpoint2= fetchEndpoint;
            if (fetchOptions.method ==="POST" || fetchOptions.method === "PUT" ){
                endpoint2=endpoint;
              }
              const response = await fetch(endpoint2, fetchOptions);
              const respuesta = await response.json();
              return  JSON.parse( decode_utf8(JSON.stringify(respuesta)) );
        } catch (e) {
          throw e;  
        }
    }
    return getPromise();
 
}

export function apiEndpoint(endpoint:string, qs:any) {
    let query = '';
  
    if (qs) {
      query = `?${qs}`;
    }
  
    return `${endpoint}${query}`;
  }

  export function apiOptions(options:options) {
    const {
      method = 'GET',
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body = false
    } = options;
  
    const newOptions:options = {
      method,
      headers,
      };
  
    if (body) {
     
      newOptions.body = body;
    }
  
    return newOptions;
  }
  
