// todo vamos a mejorar la tranciciones 
import React from 'react'
import {  Route, Routes as Routes1, useLocation } from 'react-router-dom';
import  Home  from '../containers/Home/Home';
import App from '../App'
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";
interface InterfaceClass{
           appear:string,
            appearActive: string,
            appearDone:string,
            enter: string,
            enterActive: string,
            enterDone: string,
            exit: string,
            exitActive: string,
            exitDone: string
}


 let  Combinaciones:any = {
  'HomeProyecto1':{
  'css':{
    appear: '',
    appearActive: '',
    appearDone: '',
    enter: '',
    enterActive: 'Entrar',
    enterDone: '',
    exit: '',
    exitActive: 'Salir',
    exitDone: 'SalirDone'
  },
  exit:'Salir'},
  'Proyecto1Home':{
    'css':{
      appear: '',
      appearActive: '',
      appearDone: '',
      enter: '',
      enterActive: 'EntrarReverse',
      enterDone: '',
      exit: '',
      exitActive: 'Salir',
      exitDone: ''
    },
    exit:'SalirReverse'}
 }


const UpdateClass =  (Ref:any,clase:InterfaceClass ):void=>{
  
  Ref.current.props.children.props.classNames.appear = clase.appear
  Ref.current.props.children.props.classNames.appearActive = clase.appearActive
  Ref.current.props.children.props.classNames.appearDone = clase.appearDone
  Ref.current.props.children.props.classNames.enter = clase.enter
  Ref.current.props.children.props.classNames.enterActive = clase.enterActive
  Ref.current.props.children.props.classNames.exit = clase.exit
  Ref.current.props.children.props.classNames.exitActive = clase.exitActive
  Ref.current.props.children.props.classNames.exitDone = clase.exitDone
  console.log( Ref.current.props.children.props.classNames)
}

 const Routes = () => {
  /*
  let location = useLocation();
   const noderef = React.useRef<any>(null)
   const RutaAnterior = React.useRef ('')
  let titulo = location.pathname.replace('/','') 

 
    let Clases:InterfaceClass ={
      appear: '',
      appearActive: '',
      appearDone: '',
      enter: '',
      enterActive: '',
      enterDone: '',
      exit: '',
      exitActive: '',
      exitDone: ''}

*/

/*
if(noderef.current != null){
   console.log(noderef.current)
  if(Combinaciones[RutaAnterior.current+titulo]!= null){
    if(Combinaciones[RutaAnterior.current+titulo].exit != 'defaul'){
      let clases:InterfaceClass = noderef.current.props.children.props.classNames
      clases.exitActive =Combinaciones[RutaAnterior.current+titulo].exit;
      UpdateClass(noderef,clases)
    }
  }
  if(RutaAnterior.current==titulo){

    let clases:InterfaceClass = noderef.current.props.children.props.classNames
      clases.exitActive ='Ocultar';
      UpdateClass(noderef,clases)
  }else{
   
    let clases:InterfaceClass = noderef.current.props.children.props.classNames
    if( clases.exitActive !=''){
      console.log(clases)
      let index = clases.exitActive.indexOf('Ocultar');
      if ( index >=0 ){
      
        clases.exitActive =clases.exit.replace('Ocultar','');
        UpdateClass(noderef,clases)
      }
    }
    
  }

  }

if (Combinaciones[RutaAnterior.current+titulo] != null){
  Clases= Combinaciones[RutaAnterior.current+titulo].css;
}
*/
/* 
 <TransitionGroup
         ref={noderef}
      >
      <CSSTransition
            key={location.key} 
            classNames={Clases}
            timeout={1000}

             </CSSTransition>
        </TransitionGroup>
          >*/
          /*
RutaAnterior.current = titulo;
console.log(RutaAnterior.current)
key={location.key}
location={location} 
*/
  return (
    <App>
     
  
      <Routes1   >
            <Route   path='/' element = {<Home/>}   />
            <Route   path='/Home/:Item' element = {<Home/>}   />

        
        </Routes1>
      
       
    </App>
  )
}

export default Routes