import React from 'react';
import Cart3 from '../../Generales/Cart3/Cart3';
import HexagonosIcon from '../../Generales/HexagonosIcon/HexagonosIcon';
import TecInventario from '../../../Data/TecInventario';
import config from '../../../config';
const ProyectInventario = ()=>{
    
   return (
      <Cart3 img ={`${config.baseUrl}/img/Inven.jpg`}>
   <div>
   <h2  className='cart__subtitulo' style={{ fontSize:"1rem", color:"#fff" }} >Sistema de Inventario</h2>
   <ul className='detailsul'>
    <li> Inventario</li>
    <li>Ventas</li>
    <li>Pedido </li>
    <li>Proveedores </li>
    <li>Clientes </li>
    <li>Sucursales </li>
    <li>Boleta-Factura </li>
    <li>Wordpress </li>
   </ul>
   <div style={{width:"100%",margin:"20px auto" }}>
   
   <a href="https://rodwindemo.cl/Sistema"  target="_blank" className='btn__transparente'  style={{margin:"0 auto"}} >
    DEMO
   </a>
   </div>
  
   <HexagonosIcon  items ={TecInventario} Toltip={false} width = "90%" ID = "cart__tecnoInventario"/>
   
  </div> 
   </Cart3>
   )
}

export default ProyectInventario;

