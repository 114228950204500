import React, { HTMLAttributes } from "react";

interface checktProps extends HTMLAttributes<HTMLInputElement>{
  name?:string,
  checked?:boolean,
  value?:string,
}
const Switch =(props:checktProps)=>(
  
    <div className="btn-container">
    <i className="fa fa-sun-o" aria-hidden="true"></i>
      <label className="switch btn-color-mode-switch">
            <input {...props}   type="checkbox"/>
            <label htmlFor="color_mode" data-on="Dark" data-off="Light" className="btn-color-mode-switch-inner"></label>
        </label>
      <i className="fa fa-moon-o" aria-hidden="true"></i>
  </div>
)

export default Switch;