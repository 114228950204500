// TODO Mover los datos a otro archivo

import React from 'react';
import Cart3 from '../../Generales/Cart3/Cart3';
import HexagonosIcon from '../../Generales/HexagonosIcon/HexagonosIcon';
import TecProyecLearning from '../../../Data/TecProyecLearning';
import config from '../../../config';
const ProyectLearning = ()=>{
    
   return (
      <Cart3 img ={`${config.baseUrl}/img/Learning.jpg`}>
   <div>
   <h2  className='cart__subtitulo' style={{ fontSize:"1rem", color:"#fff" }} >Plataforma de Apredizaje</h2>
   <ul className='detailsul'>
    <li>Clases Online</li>
    <li>Salas</li>
    <li>Evaluaciones</li>
    <li>Ministerio de Transporte</li>
    <li>Cursos Sence</li>
    <li>Administrador de Archivos</li>
    <li>Chat-Foros</li>
    <li>Videos Tutoriales</li>
   </ul>
   <div style={{width:"100%",margin:"20px auto" }}>
   <a  href="https://sencap.cl/"  target="_blank" className='btn__transparente'  style={{margin:"0 auto"}} >
    DEMO
   </a>
   </div>
  
   <HexagonosIcon  items ={TecProyecLearning} Toltip={false} width = "90%" ID = "cart__tecnoLearning"/>
   
  </div> 
   </Cart3>
   )
}

export default ProyectLearning;