import React from 'react';
interface props {
    titulo: string;
    id:string
  }
const Titulo = ({titulo,id}:props)=>{
    return (
        <h2 id ={id} className='cart__titulo' >
        {titulo}
       <hr className='linea__perfil' />
      </h2>
    )

}

export default Titulo;