import React from 'react';
interface Props{
    titulo:string,
    subtitulo:string,
    img:string,
    video:string
}
const Header = ({titulo,subtitulo, img, video}:Props)=>{
    return (
    <header  className='header__perfil'>
        <img id ="Home" className= 'img__perfil' src={img} alt="Img" />
        <h1 className ='titulo__perfil'>{titulo}</h1>
        <hr className='linea__perfil' />
        <h2  className='titulo__perfil' >{subtitulo}</h2>
        <video className='video__perfil' autoPlay={true} loop={true} muted={true}>
            <source src={video} />
        </video>
    </header>
    )

}

export default Header;   