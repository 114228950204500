import React from "react";
import Cart4 from "../../Generales/Cart4/Cart4";
import Titulo from '../../Generales/Titulo/Titulo';
import Estudios from "../../../Data/Estudios";
const Formaciones = ()=>{
    return (
        <div>
            <Titulo id ="Estudios" titulo = "Estudios y Certificaciones" />        
             <div className="cart__layout">
            {Estudios.map((el,key)=>(
            <Cart4 key ={"cart"+key} {...el} />
            ))}
           
            </div> 
        </div>
    )
}

export default Formaciones;