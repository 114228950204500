import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './Routes/Routes';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import rootReducer from './reducers';
import {initialState as init } from './interfaces/interfaces'
import './css/main.scss'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const initialState= { 
  Init:{
    Sesion:{
      User:"",Level:0,Store:""},
    isMobile:false
  },
  Home: { "Title":"" }
  }
 
const store = configureStore({
  initialState
},rootReducer);

root.render(

      <Provider store={store} >
        <Router>
          <App />
        </Router>
      </Provider>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
