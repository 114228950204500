import React from 'react';
import Cart3 from '../../Generales/Cart3/Cart3';
import config from '../../../config';
import HexagonosIcon from '../../Generales/HexagonosIcon/HexagonosIcon';
import TecProyecBalsas from '../../../Data/TecProyecBalsas';
const ProyectBalsas = ()=>{
    
   return (
      <Cart3 img ={`${config.baseUrl}/img/Balsas.jpg`}>
   <div>
   <h2  className='cart__subtitulo' style={{ fontSize:"1rem", color:"#fff" }} >Certificación De Balsas Salvavidas y Extintores</h2>
   <ul className='detailsul'>
    <li>Evaluado Por CORFO</li>
    <li>Certificación de Balsas </li>
    <li>Certificación de Extintores</li>
    <li>Certificación de Trajes de Inmersión</li>
    <li>Certificación de Cilindro Hidrostático</li>
    <li>Control de Certificados</li>
    <li>Códigos QR</li>
    <li>Gestión de Inventario</li>
   </ul>
   <div style={{width:"100%",margin:"20px auto" }}>
   <a  href="https://rodwindemo.cl/Sistema"  target="_blank" className='btn__transparente'  style={{margin:"0 auto"}} >
    DEMO
   </a>
   </div>
  
   <HexagonosIcon  items ={TecProyecBalsas} Toltip={false} width = "90%" ID = "cart__tecnoBalsas"/>
   
  </div> 
   </Cart3>
   )
}

export default ProyectBalsas;