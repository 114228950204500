export default [
    {
      name:"React.js",
      color:'#000000',
      url:'https://4.bp.blogspot.com/-_YSVTe2ekBU/XKMntJDH0ZI/AAAAAAAAXNk/3d48i_XShWwvoMNj0YJWp2J4_Woh9dzGgCLcBGAs/s1600/reactjs%2Btutorial.png'
    },
    {
        name:"mui",
        color:'#EBEDEF',
        url:'https://mui.com/static/logo.png'
       },
    {
        name:"node.js",
        color:'#EBEDEF',
        url: "https://assets.zabbix.com/img/brands/nodejs.svg"
    },
   {
    name:"MySql",
    color:'#EBEDEF',
    url:'https://www.programandoamedianoche.com/wp-content/uploads/2014/05/MySql-Backup.png'
   }    
  ];