export default  [
    {
        name:"jquery",
        color:'#fff',
        url:'https://mpng.subpng.com/20180704/zoa/kisspng-jquery-ui-javascript-web-browser-pasargad-5b3d093837a4d4.5712269815307267122279.jpg'
    },
    {
        name:"bootstrap",
        color:'#EBEDEF',
        url:'https://seocom.agency/wp-content/uploads/2019/02/bootstrap-stack.png'
    },
   {
    name:"PHP",
    color: '#6c7cbb',
    url:'https://muchocodigo.com/wp-content/uploads/2013/11/php.jpg'
   },
   {
    name:"MySql",
    color:'#EBEDEF',
    url:'https://www.programandoamedianoche.com/wp-content/uploads/2014/05/MySql-Backup.png'
   },
   {
    name:"wordpress",
    color:'#EBEDEF',
    url:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwlknei4fM_FqNMlwvhdp60NAG6NSEFFSMahLvrSQek-7sInqLiMRLs8NDKa1TXPj_2bU&usqp=CAU'
   }
  
  ];