// TODO Mover la logica a otro Archivo CUSTON
import React from 'react'
import {Props, connector } from './configRedux'
import Perfil   from '../../component/Personalizados/CardInfo/CardInfo';
import Header from '../../component/Generales/Header/Header';
import CartsProyectos from '../../component/Personalizados/CartsProyectos/CartsProyectos';
import Formaciones from '../../component/Personalizados/Formaciones/Formaciones';
import Footer from '../../component/Personalizados/Footer/Footer';
import Tecnologias from '../../component/Personalizados/Tecnologias/Tecnologias';
import useHome from './useHome';
import SwitchLightDark from '../../component/Generales/SwitchLightDark/SwitchLightDark';
import config from '../../config';
const Home = (Props : Props) => {
 useHome();


  return (
   <main >
    <Header 
    titulo='Rodwin Primera' 
    subtitulo='Desarrollador Full Stack'
    img={`${config.baseUrl}/img/Rodwin.jpg`}
    video='https://www.xaviro.com/assets/c97fb08d9006a59b6013.mp4'
    />
    <SwitchLightDark />
    <Perfil />
    <Tecnologias />
    <CartsProyectos/>
    <Formaciones />
    <Footer />
     </main>
  )
}
export default connector(Home)